import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loader from './loader';

function VisualSearch2(props) {
    const [openFilters, setOpenFilters] = useState({});
    const [loading, setloading] = useState(false);
    const [totalProds, settotalProds] = useState(true);
    const [facets, setfacets] = useState([]);
    const [searchMetaData, setsearchMetaData] = useState({});
    const [Products, setProducts] = useState([]);
    const [perPage, setperPage] = useState(0);
    const [AppliedFacet, setAppliedFacet] = useState({});
    const [ImageType, seImageType] = useState("");
    const [ImageData, seImageData] = useState("");


    const[loadmore,setloadmore] = useState([])
    const [SortBy, setSortBy] = useState("*");


    function convertPricePerSqmToSqft(pricePerSqm) {
        const conversionFactor = 10.7639;
        return (pricePerSqm / conversionFactor).toFixed(0);
      }
    let loadMore = async () => {
        const result = {};
        let obj = {
            ...AppliedFacet,
        };
      
        for (const [key, value] of Object.entries(obj)) {
            if (value != null && value !== '') {
                result[key] = value;
            }
        }
        setAppliedFacet({ ...result });
        const urlObj = new URL(`https://search.unbxd.io//v2.0/sites/ss-unbxd-aapac-prod-orientbell53741713874410/images`);
        Object.entries(result).forEach(([k, v]) => {
          const encodedFilter = `${k}:"${v}"`; 
          urlObj.searchParams.append('filter', encodedFilter);
      });
      urlObj.searchParams.append('rows', perPage + 9);
    //   if(SortBy != "*"){
    //     urlObj.searchParams.append('sort', `${SortBy}`);
    //   }
      setperPage(perPage + 9)
      try {
        getVisualResult(urlObj.toString(), ImageType, ImageData)
      } catch (error) {
        console.log(error)
        swal("Something went wrong please try again later")
      }
      
        console.log(urlObj.toString(), ImageType, ImageData);
      };
      let getResultwithFacet = async (key, value) => {
        const result = {};
        let obj = {
            ...AppliedFacet,
            [key]: value
        };
    
        for (const [key, value] of Object.entries(obj)) {
            if (value != null && value !== '') {
                result[key] = value;
            }
        }
    
        setAppliedFacet({ ...result });
    
        const urlObj = new URL(`https://search.unbxd.io//v2.0/sites/ss-unbxd-aapac-prod-orientbell53741713874410/images`);
        Object.entries(result).forEach(([k, v]) => {
          const encodedFilter = `${k}:"${v}"`; 
          urlObj.searchParams.append('filter', encodedFilter);
      });
       urlObj.searchParams.append('rows', 9);
      setperPage(9)
    
      try {
        getVisualResult(urlObj.toString(), ImageType, ImageData)
      } catch (error) {
        console.log(error)
        swal("Something went wrong please try again later")
      }
    
        console.log(urlObj.toString(), ImageType, ImageData);
    };
    let applysort = async (s) => {
        const result = {};
        let obj = {
            ...AppliedFacet,
        };
      
        for (const [key, value] of Object.entries(obj)) {
            if (value != null && value !== '') {
                result[key] = value;
            }
        }
      
        setAppliedFacet({ ...result });
      
        const urlObj = new URL(`https://search.unbxd.io//v2.0/sites/ss-unbxd-aapac-prod-orientbell53741713874410/images`);
        Object.entries(result).forEach(([k, v]) => {
          const encodedFilter = `${k}:"${v}"`; 
          urlObj.searchParams.append('filter', encodedFilter);
      });
      urlObj.searchParams.append('rows', perPage);
      setSortBy(s)
      if(s != "*"){
        urlObj.searchParams.append('sort', `${s}`);
      
      }
      try {
        getVisualResult(urlObj.toString(), ImageType, ImageData)
      } catch (error) {
        console.log(error)
        swal("Something went wrong please try again later")
      }
      
        console.log(urlObj.toString(), ImageType, ImageData);
      };
      const getVisualResult = async (APIUrl, typee, dataa) => {
        setloading(true); // Set loading to true before the request
        const options = {
            method: 'POST',
            url: APIUrl,
            headers: {
                'Content-Type': typee, // Ensure this is just the MIME type (image/jpeg, image/png, etc.)
                Authorization: '5a4b9251b67027ff94d260e4ac6ac09e', // Replace with your actual API key
            },
            data: dataa, // Base64 data without the "data:image/jpeg;base64," prefix
        };
    
        axios(options)
            .then((response) => {
                setloading(false); // Set loading to false after success
                // handle response data
                settotalProds(response.data.response.numberOfProducts)
                setProducts(response.data.response.products)
                setperPage(response.data.response.products.length)
                setsearchMetaData(response.data.searchMetaData)
                setfacets(response.data.facets.text.list)
                console.log(response.data);
            })
            .catch((error) => {
                setloading(false); // Set loading to false after error
                console.error('Error:', error);
            });
    };
    
    useEffect(() => {
        if (props.base64) {
            // Split base64 string into type and actual base64 data
            let splitdata = props.base64.split(',');
    
            // Ensure correct MIME type is extracted (e.g., "image/jpeg")
            let mimeType = splitdata[0].match(/data:(.*?);base64/)[1]; // Extract "image/jpeg" or similar
            seImageData(splitdata[1])
            seImageType(splitdata[0].split(':')[1])
            // Pass the extracted MIME type and base64 data to the API call
            getVisualResult(
                'https://search.unbxd.io/v2.0/sites/ss-unbxd-aapac-prod-orientbell53741713874410/images',
                splitdata[0].split(':')[1],
                splitdata[1] // Base64 data without prefix
            );
        }
    }, [props.base64]); // Trigger effect when props.base64 changes
    
    // Toggle the specific filter based on index
    const toggleFilter = (index) => {
        setOpenFilters((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle the current filter's open state
        }));
    };  return (
    
    
   <div class='new-visual-search-modal'>
    {loading ? <Loader/> :
        <Modal show={props.base64} onHide={()=>props.setbase64content(false)} className='visual-modal'>
   <Modal.Dialog >
        <Modal.Header closeButton>
        </Modal.Header>

        <Modal.Body>
          <div className='search-similar-product-sectio'>
            <div className='search-similar-product-left'>
                <div className='search-similarproduct-head'>
                    <h6>Shopping Matches</h6>
                </div>
                <div className='similarproduct-left-row-data'>
                <div className='similarproduct-left-scroll-bx'>
                    <div className='similarproduct-img'>
                        <img src={props.base64}></img>
                    </div>
                        <div className='similarproduct-filter'>
                            <div className='similarproduct-filter-row'>
                                {Array.isArray(facets) && facets.map((face, index) => (
                                    <div className='similarproduct-filter-col' key={index}>
                                        <div
                                            className={openFilters[index] ? 'flt-type-heading open' : 'flt-type-heading'}
                                            onClick={() => toggleFilter(index)}
                                        >
                                            {face.displayName}
                                        </div>
                                        {openFilters[index] && (
                                            <div className='flt-type-listing'>
                                                <ul class="ais-RefinementList-list">
                  {(loadmore.includes(face.displayName) && face.values.filter((f)=>typeof f != "number").length > 3) ? face.values.filter((f)=>typeof f != "number").map((cc)=>{
                  return (
                  <li class="ais-RefinementList-item"><label class="ais-RefinementList-label"><input class="ais-RefinementList-checkbox" type="checkbox" onChange={(e)=>{
                    if(e.target.checked){
                      getResultwithFacet(face.facetName, cc ) 
                    } else {
                      getResultwithFacet(face.facetName, "" ) 
                    }
                  }} checked={AppliedFacet[face.facetName] == cc}/><span class="ais-RefinementList-labelText">{cc}</span></label></li>
                  )
                })
               : <>{face.values.filter((f)=>typeof f != "number").slice(0, 3).map((cc)=>{
                  return (
                  <li class="ais-RefinementList-item"><label class="ais-RefinementList-label"><input class="ais-RefinementList-checkbox" type="checkbox" onChange={(e)=>{
                    if(e.target.checked){
                      getResultwithFacet(face.facetName, cc ) 
                    } else {
                      getResultwithFacet(face.facetName, "" ) 
                    }
                  }} checked={AppliedFacet[face.facetName] == cc}/><span class="ais-RefinementList-labelText">{cc}</span></label></li>
                  )
                })
                }
                {face.values.filter((f)=>typeof f != "number").length > 3 &&
                <p onClick={()=>{
                  setloadmore([...loadmore, face.displayName])
                }} style={{color:"blue", marginBottom:"0px !important"}}>load more</p>}
                </>}
                </ul>
                                                <ul>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                  </div>
                </div>
            </div>
            <div className='search-similar-product-right'>
                <div className='search-similarproduct-head'>
                    <div className='similarproduct-select'>
                       
                        <select class="select-bx" value={SortBy} onChange={(e)=>{
                applysort(e.target.value)
              }}>
                <option value="*">Relevance</option>
                <option value="price desc">Price (high to low)</option>
                <option value="price asc">Price (low to high)</option>
              </select>
                    </div>
                    <div className='similarproduct-item'>
                        <p>{totalProds} items</p>
                    </div>
                </div>
                <div className='searchsimilar-prodect-section'>
                <div className='searchsimilar-prodect-row'>
                    {Products.length ? Products.map((prod)=>{
                        return (
                            <div className='searchsimilar-prodect-col'>
                            <a href={prod.productUrl.replace("https://server.orientbell.com/", "/")}>
                                <div className='searchsimilar-prodect-img'>
                                    <img src={prod.imageUrl.length ? prod.imageUrl[0] : ""}></img>
                                </div>
                                <div className='searchsimilar-prodect-text'>
                                    <h6>{prod.title}</h6>
                                    <p>MRP ₹ {convertPricePerSqmToSqft(prod.price)} /- Sq.ft</p>
                                </div>
                            </a>
                        </div>
                        )
                    }) : <p>Mo Results Found</p>}
                   
                    
                </div>
                {perPage != settotalProds && 
                <div className='searchsimilar-loar-more-btn'>
                    <a href="#" onClick={(e)=>{
                        e.preventDefault()
                        loadMore()
                    }}>Load More</a>
                </div>}
                </div>
            </div>
          </div>
        </Modal.Body>

      </Modal.Dialog>
      </Modal>}
      </div>
   
    
  )
}

export default VisualSearch2